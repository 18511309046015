.tableContainer {
  table-layout: fixed;
  width: 95% !important;
  margin: 0 30px 0 30px !important;
}

.hourRow {
  height: 55px;
  border-bottom: 1px solid #d1d1d1;
  font-size: 14px !important;
  padding: 5px 5px !important;
}

.eventRow {
  border-bottom: 1px solid #d1d1d1;
  border-left: 1px solid #eae9ee;
  padding: 5px 5px !important;
}

.weekHeader {
  border-bottom: solid 3px #a9ba37 !important;
}

.weekDay {
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  font-size: 22px;
  color: #8e9631;
}

.dayNumber {
  margin: auto;
  padding: 0.6rem;
  font-size: 20px;
}

.event {
  position: relative;
  cursor: pointer;
  text-align: left;
  font-size: 0.7rem;
  border-radius: 3px;
  height: auto;
  --duration-height: 0;
  border: 1px solid #ffffff;
}

.event:after {
  position: absolute;
  content: '';
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  height: calc(var(--duration-height) * 55px);
  background-color: #8e9631;
  border-radius: 0 0 3px 3px;
}

.eventTitle {
  color: #ffffff;
  margin: 0 !important;
  font-size: 16px;
}

.event > * {
  padding: 0.5rem;
  justify-content: center;
}

.popoverPaperContainer {
  max-height: 50% !important;
  max-width: 60% !important;
}

.popoverEvent > * {
  margin: 1rem;
}

.popoverEventHeader {
  margin-bottom: 0;
}

.popoverEventHeader > * {
  margin: 1rem 1rem 0 0;
}

.popoverEventHoursInfoContainer > * {
  margin: 0.5rem;
}

.popoverButtons {
  display: flex;
  column-gap: 0.5rem;
}

.popoverEventCronTooltip {
  background-color: rgb(97, 97, 97) !important;
}

.popoverEventCronTooltip > p {
  color: #fff;
}

.popoverEventCategory {
  margin-bottom: 0;
}

.popoverEventInfo > * {
  flex-grow: 1;
  margin: 1rem;
}

.popoverEventTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.popoverEventTable > tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.popoverEventTable tr {
  border: 1px solid black;
  text-align: center;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
}
