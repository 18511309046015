@font-face {
  font-family: 'Montserrat-Light';
  src: url('../../assets/typographies/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-style: normal;
}

.headerContainer {
  background-color: #375980 !important;
}

.messengerContainer {
  display: flex;
  column-gap: 1rem;
  margin: 8px 7px 8px 8px;
}

.messagesContainer {
  min-height: 40vh;
  max-height: 40vh;
}

.searcherContainer {
  display: flex;
  column-gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.modalNewConversation {
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelCreateOrSelectConversation {
  margin: 27px 213px 192px 11px;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--gris-fondo-menu-y-textos);
  font-family: 'Montserrat-Light', sans-serif;
}

.conversationWidget {
  background-color: transparent !important;
  padding: 0px 42px 20px 20px !important;
}

.emptyConversation {
  width: 70%;
  height: 100px;
}

.blueLine {
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--verde-topbar-y-botones);
}

@media (max-width: 900px) {
  .messengerContainer {
    flex-direction: column;
    margin: -10px;
    height: 100%;
  }

  .conversationWidget {
    width: auto !important;
    margin-bottom: 20px;
  }

  .messengerWidget {
    width: auto !important;
  }

  .emptyConversation {
    width: auto !important;
    height: 100px;
  }
}

.gridPatient2 {
  background-color: transparent;
  height: 18rem;
  width: 100%;
}

.patientBox {
  background-color: transparent;
  border: 2px solid #adb84e;
  padding: 5px;
  box-shadow: none;
  overflow-x: hidden;
  height: 53%;
  overflow: auto;
}

.patientBox2 {
  display: flex;
  padding: 20px;
  padding-top: 10px;
  gap: 1rem;
}

.patientBox3 {
  display: flex;
  justify-content: center;
}

.boxMessage {
  width: 100%;
}

.somePage {
  background-color: #a9ba37;
  border-radius: 32px 32px 0px 0px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  justify-content: center;
}

.iconColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.2;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.blueColumn {
  background-color: #a9ba37;
  height: 75px;
}

.greenColumn {
  background-color: #a9ba37;
  height: 75px;
  padding-top: 19px;
}

.greenColumn2 {
  background-color: #a9ba37;
  height: 75px;
}

.chatList {
  max-height: 350px;
  overflow: auto;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.chatTitle {
  color: white;
  font-weight: 600;
  font-size: 22px;
}
