.role {
  margin-bottom: 1rem;
}

.role > * {
  margin: 0.3rem;
}

.roleNumericDay {
  color: #fff;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  background-color: #a9ba37;
  font-size: 20px;
}

.roleStringDays {
  padding-left: 20px;
  font-size: 22px;
  color: #a9ba37;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.roleStringDays p {
  margin: 0;
  color: #a9ba37;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.listContainer {
  flex-direction: column;
  margin: 20px;
}

.eventHeader {
  border-bottom: solid 3px #a9ba37;
}

.event {
  border-bottom: 1px solid #d1d1d1;
  cursor: pointer;
}

.event > * {
  padding: 1rem;
}

.selectedEvent {
  background-color: var(--color-selected-table-item);
}

.day > * {
  margin: 0.3rem;
}

.popoverPaperContainer {
  max-height: 50% !important;
  max-width: 60% !important;
}

.popoverEvent > * {
  margin: 1rem;
}

.popoverEventHeader {
  margin-bottom: 0;
}

.popoverEventHeader > * {
  margin: 1rem 1rem 0 0;
}

.popoverEventHoursInfoContainer > * {
  margin: 0.5rem;
}

.popoverButtons {
  display: flex;
  column-gap: 0.5rem;
}

.popoverEventCronTooltip {
  background-color: rgb(97, 97, 97) !important;
}

.popoverEventCronTooltip > p {
  color: #fff;
}

.popoverEventCategory {
  margin-bottom: 0;
}

.popoverEventInfo > * {
  flex-grow: 1;
  margin: 1rem;
}

.popoverEventTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.popoverEventTable > tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.popoverEventTable tr {
  border: 1px solid black;
  text-align: center;
}

.alignTextCenter {
  padding: 0;
}

.noEvents {
  position: relative;
  top: 50%;
  text-align: center;
  margin: 0;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.modalGenerateExercises {
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 32px;
}

.hidden {
  display: none;
}

.visible {
  visibility: visible;
}

@media (max-width: 400px) {
  .popoverEventHoursInfoContainer {
    flex-direction: column;
  }
}
