.trainingContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.trainingImage {
  border-radius: 10px;
  width: 100%;
  height: auto;
}
.dateText {
  font-size: 1.5rem;
  color: #575757;
}
.description {
  margin: 0 3.5rem 3.5rem;
  font-family: Montserrat-Regular, sans-serif;
}
.descriptionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #575757;
  border-bottom: 4px solid #a9ba37;
}

.titleData {
  font-weight: bold;
}
.textDescription {
  font-size: 1.5rem;
  color: #575757;
  white-space: pre-line;
}

.buttons {
  display: flex;
  margin-left: -7px;
}

.styleCompleted {
  font-size: 1.5rem;
  color: #a9ba37;
  margin-left: 7px;
  font-weight: bold;
}

.styledNotcompleted {
  font-size: 1.5rem;
  color: #9f3a38;
  margin-left: 7px;
  font-weight: bold;
}

.contentData {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 32px;
  margin-bottom: 30px;
  border: 2px dotted #adb84e;
  width: 70%;
}

@media (min-width: 600px) {
  .styleCompleted {
    font-size: 0.7rem;
  }
  .styledNotcompleted {
    font-size: 0.7rem;
  }

  .textDescription {
    font-size: 0.7rem;
  }

  .dateText {
    font-size: 0.7rem;
  }
}

@media (min-width: 899px) {
  .styleCompleted {
    font-size: 0.8rem;
  }
  .styledNotcompleted {
    font-size: 0.8rem;
  }

  .textDescription {
    font-size: 0.8rem;
  }

  .dateText {
    font-size: 0.8rem;
  }
}

@media (min-width: 1025px) {
  .styleCompleted {
    font-size: 0.9rem;
  }
  .styledNotcompleted {
    font-size: 0.9rem;
  }

  .textDescription {
    font-size: 0.9rem;
  }

  .dateText {
    font-size: 0.9rem;
  }
}

@media (min-width: 1280px) {
  .styleCompleted {
    font-size: 1.2rem;
  }
  .styledNotcompleted {
    font-size: 1.2rem;
  }

  .textDescription {
    font-size: 1.2rem;
  }

  .dateText {
    font-size: 1.2rem;
  }
}
