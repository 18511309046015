.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem;
  height: 200px;
  text-align: center;
  border-radius: 4px;
}

.avatar {
  width: 100px !important;
  height: 100px !important;
}

.textAvatar {
  font-size: 0.7rem !important;
}

@media (max-width: 600px) {
  .container {
    width: 100px;
  }

  .avatarContainer {
    width: 80px;
    height: 160px;
  }

  .avatar {
    width: 80px !important;
    height: 80px !important;
  }

  .textAvatar {
    font-size: 0.5rem !important;
  }
}

.profilepic {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic:hover .profilepic__image {
  opacity: 0.5;
}

.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__icon {
  color: black;
  padding-bottom: 8px;
  margin-right: 8px;
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  width: 50%;
  text-align: center;
  color: black;
  margin-right: 8px;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  boxshadow: 24;
  background-color: white;
  border-radius: 32px;
}
