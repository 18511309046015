.chartBoxTitle {
  font-weight: bold;
  font-family: Montserrat-Regular, sans-serif !important;

}

.StatsHistory {
  margin-left: 30px !important;
  font-family: Montserrat-Regular, sans-serif !important;
}

.containerStatistics {
  height: auto !important;
  min-height: 100px !important;
}

@media (min-width: 600px) {
  .chartBoxTitle {
    font-size: 0.9rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }

  .chartBoxInfo {
    font-size: 1.2rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
  .StatsHistory {
    font-size: 0.7rem;
    margin-top: -10px;
    font-family: Montserrat-Regular, sans-serif !important;
  }
}

@media (min-width: 899px) {
  .chartBoxTitle {
    font-size: 1rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }

  .chartBoxInfo {
    font-size: 1.6rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
  .StatsHistory {
    font-size: 0.8rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
}

@media (min-width: 1025px) {
  .chartBoxTitle {
    font-size: 1.2rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }

  .chartBoxInfo {
    font-size: 1.2rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
  .StatsHistory {
    font-size: 0.8rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
}

@media (min-width: 1280px) {
  .chartBoxTitle {
    font-size: 1.4rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }

  .chartBoxInfo {
    font-size: 1.5rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }

  .StatsHistory {
    font-size: 1.3rem;
    font-family: Montserrat-Regular, sans-serif !important;
  }
}
