.header {
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
  margin-bottom: 30px;
}

.treatCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.treatCard {
  display: inline-block;
  padding: 1rem;
  max-height: 500px;
  max-width: 700px;
  margin: 50px;
}

.dataButtonContainer {
  margin: 10px;
  padding: 10px;
}

.dataButton {
  height: 50px;
  width: 200px;
}

.symptomContainer {
  display: flex;
  align-items: center;
  justify-content: left;
}

.genderIcon {
  width: 20px;
  margin-left: 5px;
}

.listContainer {
  background-color: #ffffff;
  border-bottom: solid 1px #adadad;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.forwardIcon {
  margin-left: 1rem;
}

.listBlock {
  display: inline-flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 280px;
  background: #68b3e0;
  border-radius: 6px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  height: 60px;
  margin: 10px;
  overflow: hidden;
}

.iconBlock {
  background: #0063a6;
  height: 60px;
  width: 60px;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.centerBlock {
  text-align: left;
  display: inline-block;
}

.textBlock {
  color: #fff;
  margin-left: 10px;
  font-size: 14px;
}

.centerBlock2 {
  text-align: left !important;
  display: block !important;
}

@media (max-width: 920px) {
  .centerBlock {
    display: inline-block;
    text-align: center;
  }
}
