.containerInside {
  flex-direction: column;
  width: 650px;
  display: flex;
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 32px;
}

.agoraContainer {
  display: flex;
  justify-content: center;
  padding-top: 13%;
}

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 3%;
}

.alert {
  height: 90px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 3.5%;
}
