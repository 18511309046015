.toolbar {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  background-color: #f9f9f9 !important;
  font-family: 'Montserrat', sans-serif !important;
}

.year h6 {
  color: #adb84e;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -0.004em;
  text-align: left;
}

.date h6 {
  color: #575757;
  text-transform: capitalize;
}

.day h6 {
  color: #575757;
  font-size: 18px;
  font-weight: lighter;
  text-transform: capitalize;
}
