.rule {
  border: 2px solid #000;
  border-radius: 5px;
  margin-left: 0%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-right: -3px;
}

.selectRule {
  color: #6c757d;
  border-radius: 5px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  text-align-last: center;
}

.trash {
  color: red;
  margin-left: 12%;
}
