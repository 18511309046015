.toAsc {
  transform: rotate(180deg);
}

.toDesc {
  transform: rotate(0deg);
}

.iconBlock {
  margin: auto;
  width: 10%;
  padding: 20px 0;
  float: right;
}

.inputSearcher {
  width: 100%;
  height: 100%;
}

.containerBox {
  width: 100%;
  padding-top: 15px;
}

.inputBox {
  width: 100%;
}

.filterListIcon {
  margin: auto;
  width: 70%;
}

.addNewConversationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
}

.addNewConversationButton {
  margin-top: 10px;
  width: 207px !important;
  height: 33px !important;
  gap: 0px !important;
  border-radius: 32px !important;
  background: #adb84e !important;
  border: 2px solid #adb84e !important;
  color: #ffffff !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 15px !important;
  letter-spacing: -0.004em !important;
}

.filterByChatText {
  font-family: Montserrat, sans-serif !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.004em !important;
  color: #adb84e !important;
  margin-bottom: 10px !important;
  text-transform: uppercase !important;
}

.addNewConversationFab {
  background-color: #f17d32 !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
