.articleWrapper {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1rem;
  overflow: auto;
  padding: 24px;
  height: 100%;
}

.actions:hover {
  cursor: pointer;
  transform: scale(1.5);
  transition: all 0.2s ease-in-out;
}

.title {
  font-size: 2.5rem;
  text-align: center;
  border-bottom: 1px solid darkgray;
  padding-bottom: 1.2rem;
  overflow: hidden;
  margin-top: 1.1em;
}

.tagLink {
  padding: 0 0.5rem;
}

.tags {
  font-size: 1rem;
  border-bottom: 1px solid darkgray;
  padding-bottom: 2rem;
}

.content {
  text-align: justify;
  font-size: 1.2rem;
}

.files {
  border-top: 1px solid darkgray;
}

.backButton {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 20px;
}
