@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../assets/typographies/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-style: normal;
}
.container {
  margin: 50px;
}

.containerBoxLittle {
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 32px;
  padding: 22.5px 22.6px 12.3px 25px;
}

.containerBoxLarge {
  width: 100%;
  height: auto;
  padding: 22.5px 22.6px 12.3px 25px;
  border-radius: 32px;
  background-color: #fff;
}

.containerBoxStatistics {
  width: 100%;
  height: auto;
  padding: 22.5px 22.6px 12.3px 25px;
  border-radius: 32px;
  background-color: #fff;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 25px;
  font-weight: 800;
  line-height: 30.48px;
  letter-spacing: -0.004em;
  text-align: left;
}

.imageBox {
  padding: 4px;
  margin-bottom: 4px;
}

.buttonTrainingOpenMenuLeft {
  background: #adb84e !important;
  border: 2px solid #adb84e !important;
  color: #fafafa !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  padding: 0px 20px !important;
  font-size: 10px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
}

.styleInfoBox {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 1rem;
}

.titleBoxPatientStatistics {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxMedications {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.statisticsText {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 0.9rem;
}

.styleHistorialButton {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 0.7rem;
  margin-top: -10px !important;
}

.textAmount {
  font-family: Montserrat-Regular, sans-serif;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: normal;
  text-align: right;
  color: #5c5c5c;
}

.imageCalendar {
  margin-left: 10px;
}

.titleUpdateBox {
  margin-bottom: 0;
  color: #adb84e;
}

.infoPatientBox {
}

.infoPatient {
  margin: 32.6px 167.5px 10.2px 0.5px;
  font-family: Montserrat-Regular, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  color: #5c5c5c;
}

.text {
  font-family: Montserrat-Regular, sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  color: #5c5c5c;
}

.updateBox {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.titleText {
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: normal;
  text-align: right;
  color: #5c5c5c;
}

@media (max-width: 600px) {
  .statisticsText {
    font-size: 0.9rem;
  }

  .styleInfoBox {
    font-size: 1.5rem;
  }
}

@media (max-width: 899px) {
  .statisticsText {
    font-size: 0.6rem;
  }

  .styleInfoBox {
    font-size: 0.9rem;
  }
}

@media (max-width: 1025px) {
  .statisticsText {
    font-size: 0.8rem !important;
  }

  .styleInfoBox {
    font-size: 1rem !important;
  }
}

@media (max-width: 1401px) {
  .stylesTstatisticsTextext {
    font-size: 0.5rem !important;
  }

  .styleInfoBox {
    font-size: 0.9rem !important;
  }

  .styleHistorialButton {
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }
}
