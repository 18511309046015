.noti-body {
  padding: 0;

  img {
    width: 40px;
    margin-right: 20px;
  }

  li {
    padding: 15px 20px;
    transition: all 0.3s ease-in-out;

    &.n-title {
      padding-bottom: 0;

      p {
        margin-bottom: 5px;
      }
    }

    p {
      margin-bottom: 5px;
      font-size: 13px;

      strong {
        color: #222;
      }
    }

    .n-time {
      font-size: 80%;
      float: right;
    }
  }
}

.notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;

  .noti-head {
    border-bottom: 1px solid #f1f1f1;
    padding: 15px 20px;

    a {
      text-decoration: underline;
      font-size: 13px;
    }
  }

  .noti-footer {
    border-top: 1px solid #f1f1f1;
    padding: 15px 20px;
    text-align: center;

    a {
      text-decoration: underline;
      font-size: 13px;
    }
  }

  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      padding: 20px 15px;
    }
  }
}

.b-brand {
  display: flex;
  align-items: center;

  .b-bg {
    background: $theme-color;
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
    }
  }

  .b-title {
    margin-left: 10px;
    font-weight: 100;
    color: #fff;
    font-size: 18px;
  }
}

.navbar-collapsed {
  .b-brand .b-title {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .mobile-menu {
    transform: rotateY(-90deg);
    transform-origin: 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .b-brand .b-title {
      transform: rotateY(0deg);
      opacity: 1;
    }

    .mobile-menu {
      transition-delay: 0.3s;
      transform: rotateY(0deg);
      opacity: 1;
    }
  }
}
/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);

  .loader-track {
    position: relative;
    height: 3px;
    display: block;
    width: 100%;
    overflow: hidden;

    .loader-fill {
      &:after,
      &:before {
        content: '';
        background: $theme-color;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
      }

      &:before {
        animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }

      &:after {
        animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    }
  }
}
@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -35%;
  }
}
@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
  width: 260px;

  .pro-head {
    color: #fff;
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 15px;
    position: relative;
    background: #7f247f;

    img {
      width: 40px;
      margin-right: 10px;
    }

    .dud-logout {
      color: #fff;
      padding-right: 0;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .pro-body {
    padding: 20px 0;
    margin-bottom: 0;
    list-style: none;

    li {
      a {
        color: $theme-font-color;
        font-size: 14px;
        padding: 10px 20px;

        i {
          margin-right: 10px;
        }
      }

      &.active,
      &:active,
      &:focus,
      &:hover {
        background: transparentize($primary-color, 0.9);

        > a {
          background: transparent;
        }
      }
    }
  }
}

/* header css end */
/* menu[ vartical ] css start */
.mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: $header-height;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;

  span {
    position: relative;
    display: block;
    width: 100%;
    height: 2.8px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    backface-visibility: hidden;
    border-radius: 2px;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 2.8px;
      background-color: #fff;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
    }

    &:after {
      top: 5px;
      width: 70%;
    }

    &:before {
      top: -5px;
      width: 40%;
    }
  }

  &.on {
    span {
      background-color: transparent;

      &:after,
      &:before {
        height: 2px;
        width: 100%;
      }

      &:before {
        transform: rotate(45deg) translate(4px, 4px);
      }

      &:after {
        transform: rotate(-45deg) translate(3px, -3px);
      }
    }
  }
}

.navbar-brand {
  background: $menu-dark-background;
}

/* menu [ horizontal ] css end */
/* main content start */
.pcoded-content {
  position: relative;
  display: block;
  padding: 30px;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  margin-left: $Menu-width;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
}

/* ==========================    Rsponsive Menu  end   ====================== */
/* ******************************************************************************* */
/* * Horizontal */

.theme-horizontal {
  .sidenav-horizontal-wrapper {
    flex: 0 1 100%;
    width: 0;

    .sidenav:not(.sidenav-no-animation) & .sidenav-inner {
      transition: margin 0.2s;
    }
  }

  .sidenav-horizontal-prev::after {
    border-right: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .sidenav-horizontal-next::after {
    border-left: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
// ==========================    Menu Styler Start     ======================
$style-block-width: 400px;

.menu-styler {
  z-index: 1029;
  position: relative;
  color: $theme-font-color;

  h5,
  h6 {
    color: $theme-heading-color;
    font-weight: 400;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .style-toggler {
    > a {
      position: fixed;
      right: 0;
      top: 30vh;
      transition: 0.3s ease-in-out;

      &:before {
        content: '\62';
        top: 0;
        font-size: 120px;
        position: absolute;
        right: -45px;
        font-family: 'Arial';
        z-index: 1001;
        line-height: 0;
        color: $primary-color;
        transition: 0.3s ease-in-out;
        text-shadow: 1px 0 2px transparentize($primary-color, 0.1), 3px 0 5px rgba(0, 0, 0, 0.1);
        letter-spacing: -44px;
        transform: rotate(180deg);
      }

      &:after {
        content: '\e8c3';
        top: -7px;
        font-size: 18px;
        position: absolute;
        right: 4px;
        font-family: 'feather';
        z-index: 1029;
        line-height: 1;
        color: #fff;
        transition: 0.3s ease-in-out;
        animation: anim-rotate 1.5s linear infinite;
        @media all and (-ms-high-contrast: none) {
          line-height: 0.5;
        }
      }
    }
  }

  &.open {
    .style-toggler {
      > a {
        right: $style-block-width;

        &:after {
          content: '\e847';
          top: -7px;
          font-size: 18px;
          animation: anim-rotate 0s linear infinite;
        }
      }
    }

    .style-block {
      right: 0;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }

  .style-block {
    position: fixed;
    top: 0;
    right: -#{$style-block-width};
    width: $style-block-width;
    height: 100vh;
    background: #fff;
    z-index: 1030;
    transition: 0.3s ease-in-out;
    padding: 25px 20px 20px;
  }

  .mst-scroll {
    height: calc(100vh - 175px);
    background-color: transparentize($primary-color, 0.97);
    padding: 0 15px;
  }

  label {
    margin-bottom: 3px;
  }

  .switch input[type='checkbox'] + .cr {
    top: 8px;
  }

  .radio {
    input[type='radio'] {
      + .cr {
        margin-right: 10px;

        &:after,
        &:before {
          margin-right: 3px;
        }
      }
    }
  }

  h6 {
    position: relative;
    margin: 10px 0 15px;

    &:after {
      content: '';
      background-color: $primary-color;
      position: absolute;
      left: 0;
      bottom: -8px;
      width: 40px;
      height: 2px;
    }
  }

  .nav-pills {
    padding: 0;
    box-shadow: none;

    li {
      width: calc(100% / 3);
    }
  }

  .tab-content {
    padding: 15px;
    box-shadow: none;
    background-color: transparentize($primary-color, 0.97);
    border-top: 2px solid $primary-color;
  }
  @media only screen and (max-width: 992px) {
    display: none;
  }

  .config-scroll {
    height: calc(100vh - 430px);
  }
}

.lay-customizer .theme-color,
.theme-color {
  display: block;
  position: relative;
  padding: 5px 5px 0;
  margin-bottom: 10px;

  > a {
    position: relative;
    width: 45px;
    height: 30px;
    border-radius: 2px;
    display: inline-block;
    margin-right: 8px;
    background: $theme-background;
    overflow: hidden;
    box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);

    span {
      width: 100%;
      position: absolute;

      &:after,
      &:before {
        content: '';
        height: 100%;
        position: absolute;
      }

      &:before {
        width: 30%;
        left: 0;
        background: $menu-dark-background;
      }

      &:after {
        width: 70%;
        right: 0;
        background: $theme-background;
      }
    }

    > span:nth-child(1) {
      height: 30%;
      top: 0;
    }

    > span:nth-child(2) {
      height: 75%;
      bottom: 0;
    }

    &.active {
      box-shadow: 0 0 0 3px $primary-color;

      &:before {
        content: '\e8dc';
        top: -1px;
        font-size: 20px;
        position: absolute;
        left: 12px;
        font-family: 'feather';
        z-index: 1001;
        color: $primary-color;
        transition: 0.3s ease-in-out;
        text-shadow: 0 1px 3px transparentize($primary-color, 0.1), 0 3px 8px rgba(0, 0, 0, 0.1);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
      }
    }

    &[data-value='menu-light'] {
      span {
        &:before {
          background: $menu-light-background;
        }
      }
    }

    &[data-value='dark'] {
      span {
        &:after {
          background: darken($dark-layout, 7%);
        }
      }
    }

    &[data-value='reset'] {
      background: $danger-color;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      top: -10px;
      width: 135px;
    }
  }

  &.small {
    > a {
      width: 25px;
      height: 25px;
      border-radius: 50%;

      &:before {
        font-size: 15px;
        left: 5px;
        top: 1px;
      }

      > span {
        display: none;
      }
    }
  }

  &.header-color {
    @each $value in $color-header-name {
      $i: index($color-header-name, $value);

      > a {
        &[data-value='header-#{'' + $value}'] {
          > span:nth-child(1) {
            &:after {
              background: nth($color-header-color, $i);
            }
          }
        }
      }

      &.small > a {
        &[data-value='header-#{'' + $value}'] {
          background: nth($color-header-color, $i);
        }
      }
    }
  }

  &.navbar-color {
    @each $value in $color-menu-name {
      $i: index($color-menu-name, $value);

      > a {
        &[data-value='navbar-#{'' + $value}'] {
          > span {
            &:before {
              background: nth($color-menu-color, $i);
            }
          }
        }
      }
    }
  }

  &.brand-color {
    @each $value in $color-header-name {
      $i: index($color-header-name, $value);

      > a {
        &[data-value='brand-#{'' + $value}'] {
          > span:nth-child(1) {
            &:before {
              background: nth($color-header-color, $i);
            }
          }
        }
      }

      &.small > a {
        &[data-value='brand-#{'' + $value}'] {
          background: nth($color-header-color, $i);
        }
      }
    }
  }

  &.active-color {
    > a[data-value='active-default'] {
      background: $theme-color;
    }
    @each $value in $color-active-name {
      $i: index($color-active-name, $value);

      > a {
        &[data-value='active-#{'' + $value}'] {
          background: nth($color-active-color, $i);
        }
      }
    }
  }

  &.title-color {
    > a[data-value='title-default'] {
      background: #fff;
    }
    @each $value in $color-title-name {
      $i: index($color-title-name, $value);

      > a {
        &[data-value='title-#{'' + $value}'] {
          background: nth($color-title-color, $i);
        }
      }
    }
  }

  &.navbar-images {
    > a {
      height: 90px;
      background-size: cover;
      background-position: center center;

      span {
        display: none;
      }

      &.active:before {
        top: 35px;
        content: '\e87c';
      }
    }
  }

  &.bg-images {
    > a {
      border-radius: 2px;
      background-size: cover;
      background-position: center center;

      span {
        display: none;
      }

      &.active:before {
        top: 35px;
        content: '\e87c';
      }
    }
  }
  // prebuild layout
  &.prelayout-color {
    > a {
      margin-bottom: 5px;
      &[data-value='l1'] {
        > span {
          &:nth-child(1) {
            z-index: 1;

            &:before {
              background: linear-gradient(
                to bottom,
                $primary-color 0%,
                $primary-color 80%,
                $menu-dark-text-color 80%,
                $menu-dark-text-color 90%,
                $primary-color 91%,
                $primary-color 100%
              );
            }
          }

          &:nth-child(2) {
            &:before {
              background: linear-gradient(
                to right,
                $menu-dark-background 0%,
                $menu-dark-background 20%,
                $menu-dark-text-color 25%,
                $menu-dark-text-color 30%,
                $menu-dark-background 20%,
                $menu-dark-background 100%
              );
            }
          }
        }
      }
      &[data-value='l2'] {
        > span {
          &:nth-child(1) {
            &:before {
              background: $menu-dark-background;
            }
          }

          &:nth-child(2) {
            &:before {
              background: $theme-color;
            }
          }
        }
      }

      &[data-value='l2-2'] {
        > span {
          &:nth-child(1) {
            &:before {
              background: $menu-dark-background;
            }
          }

          &:nth-child(2) {
            &:before {
              background: $theme-color2;
            }
          }
        }
      }

      &[data-value='l3'] {
        > span {
          &:nth-child(1) {
            &:before {
              background: $menu-dark-background;
            }
          }

          &:nth-child(2) {
            &:before {
              background: $primary-color;
            }
          }
        }
      }

      &[data-value='l4'] {
        > span {
          &:nth-child(1) {
            &:before {
              background: $theme-color;
            }
          }

          &:nth-child(2) {
            &:before {
              background: $dark-layout;
            }
          }
        }
      }

      &[data-value='l4-2'] {
        > span {
          &:nth-child(1) {
            &:before {
              background: $theme-color2;
            }
          }

          &:nth-child(2) {
            &:before {
              background: $dark-layout;
            }
          }
        }
      }

      &[data-value='l5-h'] {
        > span {
          &:nth-child(1) {
            height: 40%;

            &:after,
            &:before {
              background: linear-gradient($primary-color 50%, #fff 0%);
              box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
              z-index: 1;
            }
          }

          &:nth-child(2) {
            height: 60%;

            &:after,
            &:before {
              background: $theme-background;
            }
          }
        }
      }

      &[data-value='l-lite'] {
        > span {
          &:nth-child(1) {
            &:after,
            &:before {
              background: nth($color-header-color, 4);
            }
          }

          &:nth-child(2) {
            &:before {
              background: #fff;
            }
          }
        }
      }

      &[data-value='l6'] {
        > span {
          &:nth-child(1) {
            height: 50%;

            &:after {
              background: nth($color-header-color, 4);
            }

            &:before {
              background: linear-gradient(nth($color-header-color, 4) 50%, #fff 0%);
              z-index: 1;
            }
          }

          &:nth-child(2) {
            height: 50%;

            &:after {
              background: $theme-background;
            }

            &:before {
              background: #fff;
            }
          }
        }
      }

      &[data-value='l8'] {
        > span {
          &:nth-child(1) {
            &:after {
              background: nth($color-header-color, 4);
            }

            &:before {
              background: nth($color-header-color, 4);
            }
          }

          &:nth-child(2) {
            &:after {
              background: $theme-background;
            }

            &:before {
              background: linear-gradient(
                #fff 0px,
                #fff 5px,
                transparentize(nth($color-header-color, 4), 0.5) 5px,
                transparentize(nth($color-header-color, 4), 0.5) 5px,
                transparentize(nth($color-header-color, 4), 0.5) 8px,
                #fff 8px,
                #fff
              );
            }
          }
        }
      }
    }
  }
}

body {
  &.layout-1 {
    .pcoded-navbar.menu-light {
      .sidemenu {
        box-shadow: inset -1px 0 0 0 rgba(169, 183, 208, 0.4);

        li {
          a:after {
            border-bottom: 1px solid rgba(181, 189, 202, 0.4);
          }
        }
      }

      .toggle-sidemenu {
        background: #f1f1f1;
        color: $menu-light-text-color;
      }
    }
    .pcoded-navbar.icon-colored {
      .sidemenu {
        $flg: 1;
        @for $k from 1 through 8 {
          @each $value in $menu-icon-color {
            > li:nth-child(#{$flg}) > a {
              color: $value;
            }
            $flg: $flg + 1;
          }
        }
      }
    }
  }
}

/* ====== [ Active Color end ] ======== */
/* ====== [ menu static start ] ======== */
.pcoded-navbar.menupos-static {
  position: absolute;
  height: 100%;
  min-height: 100%;
  // .navbar-wrapper,
  .datta-scroll.navbar-content {
    height: calc(100% - #{$header-height});
    min-height: 100vh;
  }
}
/* ====== [ menu static end ] ======== */
/* ====== [Boxc start ] ======== */
body.box-layout {
  position: static;

  @media only screen and (max-width: 992px) {
    position: relative;

    &.container {
      padding: 0;
    }
  }
}

/* ====== [ Hide Caption end ] ================ */
/* ==========================    Diffrent Layout Styles end     ====================== */
/* temp SCSS for document */
.doc-img {
  > a {
    position: relative;
    width: 130px;
    height: 80px;
    display: inline-block;
    border: 3px solid #fff;
    margin-right: 5px;
    background: $theme-background;
    overflow: hidden;

    span {
      width: 100%;
      position: absolute;

      &:after,
      &:before {
        content: '';
        height: 100%;
        position: absolute;
      }

      &:before {
        width: 30%;
        left: 0;
        background: $menu-dark-background;
      }

      &:after {
        width: 70%;
        right: 0;
        background: $theme-background;
      }
    }

    > span:nth-child(1) {
      height: 20%;
      top: 0;
    }

    > span:nth-child(2) {
      height: 80%;
      bottom: 0;
    }

    &.active {
      border-color: $primary-color;

      &:before {
        content: '\e83f';
        top: -4px;
        font-size: 20px;
        position: absolute;
        left: 10px;
        font-family: 'feather';
        z-index: 1001;
        color: $primary-color;
        transition: 0.3s ease-in-out;
        text-shadow: 0 3px 8px transparentize($primary-color, 0.1), 0 3px 8px rgba(0, 0, 0, 0.1);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.65);
      }
    }

    &[data-value='menu-light'] {
      span {
        &:before {
          background: $menu-light-background;
        }
      }
    }

    &[data-value='dark'] {
      span {
        &:after {
          background: darken($dark-layout, 7%);
        }
      }
    }
  }

  &.navbar-color {
    @each $value in $color-menu-name {
      $i: index($color-menu-name, $value);

      > a {
        &[data-value='navbar-#{'' + $value}'] {
          > span {
            &:before {
              background: nth($color-menu-color, $i);
            }
          }
        }
      }
    }
  }

  &.header-color {
    @each $value in $color-header-name {
      $i: index($color-header-name, $value);

      > a {
        &[data-value='header-#{'' + $value}'] {
          > span:nth-child(1) {
            &:after {
              background: nth($color-header-color, $i);
            }
          }
        }
      }
    }
  }

  &.brand-color {
    @each $value in $color-header-name {
      $i: index($color-header-name, $value);

      > a {
        &[data-value='brand-#{'' + $value}'] {
          > span:nth-child(1) {
            &:before {
              background: nth($color-header-color, $i);
            }
          }
        }
      }
    }
  }

  &.active-color {
    > a[data-value='active-default'] {
      background: $theme-color;
    }
    @each $value in $color-active-name {
      $i: index($color-active-name, $value);

      > a {
        &[data-value='active-#{'' + $value}'] {
          background: nth($color-active-color, $i);
        }
      }
    }
  }

  &.title-color {
    > a[data-value='title-default'] {
      background: #fff;
    }
    @each $value in $color-title-name {
      $i: index($color-title-name, $value);

      > a {
        &[data-value='title-#{'' + $value}'] {
          background: nth($color-title-color, $i);
        }
      }
    }
  }

  &.navbar-images {
    > a {
      height: 130px;
      background-size: 37px;
      background-position: left top;

      span {
        &:before {
          background: transparent;
        }
      }
    }
  }
}
@each $value in $color-menu-name {
  $i: index($color-menu-name, $value);

  .nav-link {
    &.active.h-#{'' + $value} {
      background: nth($color-menu-color, $i) !important;
    }
  }
}

/* ===================================================================================================
 ==========================    Diffrent types of NAvbar Layout Styles end     ======================
 =================================================================================================== */

body.layout-6 {
  background: $layout-6-bg-color;
  background-size: cover;
  background-attachment: fixed;
  background-position: top right;
  position: relative;

  .page-header-title + .breadcrumb > .breadcrumb-item:last-child a,
  .page-header-title h5,
  .pcoded-header .input-group .input-group-text,
  .pcoded-header .navbar-nav > li > .dropdown > .dropdown-toggle,
  .pcoded-header .navbar-nav > li > .dropdown-toggle,
  .pcoded-header .navbar-nav > li > a {
    color: #fff;
  }

  .mobile-menu span,
  .mobile-menu span:after,
  .mobile-menu span:before,
  .pcoded-navbar.menu-light .mobile-menu span,
  .pcoded-navbar.menu-light .mobile-menu span:after,
  .pcoded-navbar.menu-light .mobile-menu span:before {
    background: #fff;
  }

  .breadcrumb-item + .breadcrumb-item::before,
  .page-header-title + .breadcrumb > .breadcrumb-item a {
    color: rgba(255, 255, 255, 0.8);
  }

  .pcoded-content {
    background: $theme-background;
  }

  .main-body {
    min-height: calc(100vh - 200px);
  }

  .pcoded-navbar.menu-light .navbar-content {
    background: #fff;
    box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
  }

  /* for able pro style */
  .pcoded-content {
    margin-top: 200px;
  }

  .pcoded-content .pcoded-inner-content {
    margin-top: -200px;
    min-height: calc(100vh + 150px);
  }

  .card {
    border-radius: 5px;
  }
}

body.layout-8 {
  .card {
    box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122);
    transition: all 0.25s ease-in-out;

    &:hover {
      box-shadow: 0 0 0 1px rgba(100, 121, 143, 0.122), 0 2px 1px rgba(0, 0, 0, 0.05);
    }
  }
}

// ===  scroll bar  =====

.navbar-content {
  position: relative;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background: transparent;
}

.ps__thumb-y {
  background: #2a3446;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background: #222a38;
}

/* nav-outside */
.nav-outside {
  position: absolute;
  width: 100%;
  height: 100%;
}
