.sign {
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.signUpCol {
  margin-left: 5%;
  margin-top: 6%;
}

.signInRow {
  display: flex;
  justify-content: center;
  margin-top: 10%;
}

.signInCol {
  margin-left: 2%;
  margin-top: 10%;
}

@media screen and (max-width: 865px) {
  .signInRow {
    display: flex;
    justify-content: center;
    margin-top: 20%;

    .loginTitle {
      font-size: 20px;
    }

    .signInCard {
      width: 90%;
    }

    .signInSCol {
      margin-left: -9%;
    }

    .saveCredentials {
      font-size: 12px;
    }
  }
}
