.configurationList {
  width: calc(100% - 20px);
  background-color: var(--color-white);
  border-radius: 2rem;
}

.configurationLink {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.configurationItem {
  column-gap: 1rem;
  display: flex;
  justify-content: start;
  margin-left: 35px;
}

.version {
  padding: 10px;
  color: #757575;
}

.configurationSeparator {
  margin: 0;
  width: 95%;
}

.configurationItemLabel {
  font-size: 1.2rem;
  text-align: left;
  display: block;
}

.icon {
  width: 30px;
}
