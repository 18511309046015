.tableContainer {
  table-layout: fixed;
  width: 95% !important;
  margin: 0 30px 0 30px !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  border-radius: 27px !important;
  background: #f9f9f9;
  box-shadow: 0px 3px 3px 0px #00000040;
  border: none !important;
  margin-bottom: 4px;
}

.hourRow {
  height: 35px;
  border-bottom: 1px solid #adb84e;
  font-size: 14px !important;
  padding: 5px 5px !important;
  display: flex;
  flex-direction: column;
  max-height: 35px;
}

.hour {
  text-align: center;
}
.containerEvent {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-height: 35px;
}
.eventRow {
  border-bottom: 1px solid #adb84e;
  border-left: 1px solid #adb84e;
  padding: 0px 5px !important;
  width: 100%;
  max-height: 35px !important;
  height: 35px !important;
}

.buttonAddAppointmentContainer {
  display: flex;
  justify-content: flex-end;
  margin: 11px 55px 18px 0 !important;
}

.buttonAddAppointment {
  border-radius: 27px !important;
  background-color: #adb84e !important;
  border: 1px solid #adb84e !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  color: #f9f9f9 !important;
}

.addIcon {
  margin-right: 4px;
}

.weekHeader {
  display: flex;
  border-radius: 27px !important;
  width: 9.98vw;
}

.weekDay {
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #8e9631;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 0.7rem;
}

.weekNoDay {
  padding: 0 0 0 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #8e9631;
  border: 1.5px solid #8e9631;
  border-radius: 27px;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 0.7rem;
}

.weekDayNumber {
  color: #8e9631;
  margin-right: 5px;
  font-family: Montserrat-SemiBold, sans-serif;
  font-size: 1.5rem;
}

.weekDayName {
  text-transform: uppercase;
  color: #474747;
}

.dayNumber {
  margin: auto;
  padding: 0.6rem;
  font-size: 20px;
}

.event {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-size: 0.7rem;
  border-radius: 32px 32px 0 0;
  height: auto;
  --duration-height: 0;
  padding: 11px 4px 0px 4px;
}

.eventNotSameDay {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-size: 0.7rem;
  border-radius: 32px;
  height: 34px;
  padding: 2px 4px;
}

.event:after {
  position: absolute;
  content: '';
  top: calc(100% - 3px);
  left: 0;
  right: 0;
  height: calc(var(--duration-height) * 27px);
  background-color: var(--col);
  border-radius: 0 0 32px 32px;
  padding: 0 5px !important;
  margin: 0;
}

.eventTitle {
  color: #474747;
  margin: 0 !important;
  font-size: 12px;
  line-height: 12px;
  font-family: Montserrat-Regular, sans-serif;
  text-align: center;
  font-weight: 500;
}
.eventTitleNotSameDay {
  color: #474747;
  margin: 0 !important;
  font-size: 12px;
  line-height: 12px;
  font-family: Montserrat-Regular, sans-serif;
  text-align: center;
  font-weight: 500;
}

.iconEventCategory {
  margin-right: 4px;
  margin-left: 18px;
}

.iconEventCategoryNotSameDay {
  margin-right: 4px;
  margin-left: 18px;
}

.event > * {
  padding: 0.5rem;
  justify-content: center;
}

.eventNotSameDay > * {
  padding: 0.5rem;
  justify-content: center;
}

.popoverPaperContainer {
  max-height: 50% !important;
  max-width: 60% !important;
}

.popoverEvent > * {
  margin: 1rem;
}

.popoverEventHeader {
  margin-bottom: 0;
}

.popoverEventHeader > * {
  margin: 1rem 1rem 0 0;
}

.popoverEventHoursInfoContainer > * {
  margin: 0.5rem;
}

.popoverButtons {
  display: flex;
  column-gap: 0.5rem;
}

.popoverEventCronTooltip {
  background-color: rgb(97, 97, 97) !important;
}

.popoverEventCronTooltip > p {
  color: #fff;
}

.popoverEventCategory {
  margin-bottom: 0;
}

.popoverEventInfo > * {
  flex-grow: 1;
  margin: 1rem;
}

.popoverEventTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.popoverEventTable > tbody tr:nth-child(odd) {
  background-color: #dddddd;
}

.popoverEventTable tr {
  border: 1px solid black;
  text-align: center;
}

.eventModal {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1250px) {
  .weekDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #8e9631;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }

  .weekDayNumber {
    color: #8e9631;
    margin-right: 5px;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 1.2rem;
  }
}

@media (max-width: 920px) {
  .weekDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8e9631;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }

  .weekNoDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8e9631;
    border: 1.5px solid #8e9631;
    border-radius: 27px;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }

  .weekDayNumber {
    color: #8e9631;
    margin-right: 5px;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.9rem;
  }

  .weekHeader {
    display: flex;
    border-radius: 27px !important;
    width: 8.9vw;
  }
}

@media (max-width: 480px) {
  .weekDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8e9631;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }

  .weekNoDay {
    padding: 0 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8e9631;
    border: 1.5px solid #8e9631;
    border-radius: 27px;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.5rem;
  }

  .weekDayNumber {
    color: #8e9631;
    margin-right: 5px;
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 0.9rem;
  }

  .weekHeader {
    display: flex;
    border-radius: 27px !important;
    width: 10vw;
  }

  .eventRow {
    max-height: 27px;
    border-bottom: 1px solid #d1d1d1;
    border-left: 1px solid #eae9ee;
  }
}
