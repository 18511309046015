.select {
  min-width: 180px;
}

.checkbox {
  display: inline;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 100px 50px 0 50px;
}
