@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../assets/typographies/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
}

.button {
  font-size: 14px !important;
  text-transform: none !important;
}

.newPrimary {
  background: #adb84e !important;
  border: 2px solid #adb84e !important;
  color: #fafafa !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  padding: 0px 20px !important;
  font-size: 13px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
}

.newSecondary {
  background: transparent !important;
  border: 2px solid #adb84e !important;
  color: #adb84e !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  padding: 0px 20px !important;
  font-size: 13px !important;
  font-family: Montserrat-SemiBold, sans-serif !important;
}

.buttonLogin {
  width: 268px !important;
  height: 41px !important;
  font-size: 32px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  font-family: Montserrat-ExtraBold, sans-serif !important;
  font-weight: 800;
  padding: 11px 70px 10px !important;
  border-radius: 7px !important;
  color: #474747 !important;
  background-color: transparent !important;
  text-transform: uppercase !important;
}

.addPatientButton {
  background-color: var(--verde-topbar-y-botones) !important;
  width: 142px !important;
  height: 37px !important;
  padding: 7px 10px 8px !important;
  border-radius: 7px !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  color: #ffffff !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
}

.addResourceButton {
  background-color: var(--verde-topbar-y-botones) !important;
  width: 128px !important;
  height: 37px !important;
  padding: 9px 12px 8px !important;
  border-radius: 7px !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0 !important;
  color: #ffffff !important;
}

.addNewEventButton {
  background-color: #adb84e !important;
  color: #ffffff !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
  padding-bottom: 100px;
  height: 74px !important;
  border-radius: 32px !important;
  border: 2px solid #adb84e !important;
}

.transparent {
  background-color: #ffffff !important;
  border: none;
}

.newPrimaryResponsive {
  background-color: #68b3e0 !important;
}

@media (max-width: 480px) {
  .newPrimaryResponsive {
    width: 33%;
  }
}

@media (min-width: 1024px) {
  .newPrimaryResponsive {
    width: 25%;
  }
}

@media (min-width: 1440px) {
  .newPrimaryResponsive {
    width: 12%;
  }
}
