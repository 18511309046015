.mainContainer {
  overflow-y: auto;
  height: calc(100vh - 75px);
  margin-top: 75px;
}

.mainContainer > div {
  height: 100%;
  padding: 1rem 0;
}

.logo {
  margin-right: 125px;
  margin-bottom: 28px;
  margin-top: 30px;
  width: 200px;
}

/* @media (max-width: 1500px) {
  .mainContainer {
    padding: 0 0 8px;
  }
}

@media (max-width: 1200px) {
  .mainContainer {
    padding: 0 0 8px;
  }
} */

/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */
/* IT'S SET PADDING IN MAIN CONTAINER CHILD */
