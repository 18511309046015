.root {
  float: left;
  margin-right: 5%;
}

.selectWeekDay {
  width: 12rem;
}

.prueba {
  display: inline;
}

.botones {
  display: block;
  float: right;
  width: 85%;
  height: 0;
}

.inputWeek {
  min-width: 60%;
  max-width: 60%;
}

.cronExpressionContainer {
  padding: 0 !important;
}

.cronContainer {
  column-gap: 1rem;
  padding: 1rem;
  border: 1px solid var(--color-gray);
}

.mockupCronContainer {
  column-gap: 1rem;
  padding: 1rem;
  border: 1px solid #e8e7ec;
  border-radius: 32px;
}

.mockupCronTypeContainer {
  margin-top: auto;
  margin-bottom: auto;
  border-right: 1px solid #e8e7ec;
}

.mockupRepeatingContainer {
  width: 30%;
  border-left: 1px solid #e8e7ec;
}

.mockupSelect {
  min-width: 100% !important;
}

.interactiveButtonsContainer {
  row-gap: 1rem;
}
