/* Estilos necesarios */

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100% !important;
  margin-top: 50px !important;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  height: 40px;
  gap: 24px;
}

.iconLayer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  object-fit: contain;
  width: 50px;
}

.iconActive {
  filter: brightness(0) saturate(100%) invert(73%) sepia(13%) saturate(2060%) hue-rotate(26deg)
    brightness(96%) contrast(80%);
}

.text span {
  font-size: 18px !important;  
  font-family: 'Montserrat-SemiBold', sans-serif !important;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--color-white);
}

.textActive span {
  color: var(--verde-topbar-y-botones)!important;
}

/* Fin */
@media (max-width: 900px) {
  .icon {
    width: 25px;
  }
  .text span {
    font-size: 12px!important;
  }
  .listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    gap: 8px;
  }

  .listContainer {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}