.rowTask {
  margin-top: -37%;
  margin-bottom: 2%;
  font-size: 19px;
}

.rowCreatedTask {
  margin-top: -47.5%;
  margin-bottom: 2%;
  font-size: 19px;
  font-weight: bold;
}

.rowLowTask {
  display: flex;
  justify-content: center;
  color: #476fb3;
}

.rowMediumTask {
  display: flex;
  justify-content: center;
  color: #f4c22b;
}

.rowHighTask {
  display: flex;
  justify-content: center;
  color: #f44236;
}

.rowTask2 {
  background-color: #f7f7f7;
  padding-left: 1%;
  padding-right: 1%;
}
