.container {
  padding: 14px;
  border-radius: 32px;
  background: #fff;
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.header {
  margin-top: 0;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
  margin-bottom: 40px;
}

.box {
  width: 90%;
}

.textField {
  margin-left: 30px;
  width: 100%;
}

.checkBox {
  margin-left: 20px;
  width: 100%;
}

.titleLabel {
  margin-right: 85px;
}

.descriptionLabel {
  margin-right: 40px;
}

.separator {
  margin: 0 10px 0;
}

b {
  padding-right: 10px;
}
