.divSender {
  background-color: rgb(211, 226, 240);
}

.formSendMessage {
  display: flex;
  align-items: stretch;
  width: 100vw;
}

.formSendMessageDisabled {
  opacity: 0.4;
}

.divMessage {
  background-color: white;
  border-radius: 1.3rem;
  margin: 1rem 0 1rem 1rem;
  padding: 0.3rem;
}

.btnEmoji {
  cursor: pointer;
  margin-left: 0.5rem;
}

.inputMessage {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 0.2rem;
  font-size: 22px !important;
  resize: none;
}

.divSendFile,
.divSendFile > * {
  cursor: pointer;
}

.btnSendFile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
}

input[type='file'],
input[type='submit'] {
  display: none;
}

.emojiPicker {
  position: relative;
  top: -390px;
  left: 80px;
  z-index: 1;
}

.attachedElement {
  display: flex;
  align-items: center;
}

.attachedElement > p {
  margin: 0;
}

.removeAttachedElement {
  cursor: pointer;
  margin-right: 1rem;
}

.btnDisabled {
  cursor: auto !important;
  opacity: 0.3;
}
