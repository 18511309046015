.aside {
  background: linear-gradient(45deg, rgb(149, 117, 205), rgb(111, 64, 194));
  width: 220px;
  padding: 40px 20px 40px 20px;
}

.listContainer {
  margin-top: 1rem;
  overflow: no-display;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.menuList > * {
  margin: 0 0 0 0;
  padding: 0;
}

.menuItem > * {
  margin: 0.8rem;
  justify-content: center;
  color: white;
}

.logoContainer {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.epicoLogo {
  padding: 10px 10px 10px 0;
  width: 55px;
  height: 55px;
}

.epico {
  width: 90px;
  height: 30px;
}

/*
.menuDiv :hover {
  background-color: #0063a6;
}

.menuDiv :hover > * {
  color: #ffffff;
}
*/

.link,
.link h3,
.link:visited {
  text-decoration: none;
  font-size: 15px;
  font-weight: normal;
}

.linkActive div {
  background-color: #f37e31;
}

.linkActive h3 {
  color: #ffffff;
}

/* TMP TO DISABLED ELEMENTS */
.menuItemDisabled > * {
  margin: 0.8rem;
  opacity: 0.2;
}

.linkDisabled,
.linkDisabled h3 {
  text-decoration: none;
  pointer-events: none;
}

.menuIcon {
  width: 21px;
}

.menuIcon {
  width: 21px;
}
