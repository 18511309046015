.newConversationContainer {
  flex-direction: column;
  min-height: 400px;
  width: 700px;
  display: flex;
  background-color: var(--color-white);
  justify-content: space-between;
  border-radius: 32px;
  padding: 40px;
}

.buttonFileContainer {
  display: flex;
  padding: 0 10px;
  align-items: center;
  width: 100%;
  height: 47px;
  border-radius: 32px;
  border: 1px solid #e8e7ec;
}

.buttonFile {
  width: 165px;
  height: 24px;
  border-radius: 32px;
  border: 1px solid #e8e7ec;
  background: #e8e7ec;
  font-family: Montserrat-Regular, sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.004em;
  text-align: left;
}

.fileName {
  margin-left: 10px !important;
  color: #474747 !important;
  font-family: Montserrat-Regular, sans-serif !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
}

.label {
  width: 25%;
  font-weight: bold;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.chip {
  margin: 3px 5px;
}
