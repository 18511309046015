.containerForm {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  width: 100%;
  margin-right: 14px;
}

.autocomplete {
  width: 100%;
  border-radius: 32px !important;
}
.fields {
}

.form {
  width: 90%;
  overflow: auto;
  padding: 28px 48px;
}

.inputTitle {
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left !important;
  margin: 5px !important;
  color: #a9ba37 !important;
}

.label {
  width: 25%;
  font-weight: bold;
}
.dateFields {
  width: 10rem;
  margin-right: 220px !important;
}
.form {
  width: 90%;
  overflow: auto;
  padding: 28px 48px;
}

.boxAddPatient > img {
  margin: 0 50px 0 0 !important;
  object-fit: contain !important;
}

.buttons {
  margin-top: 6.5%;
  align-items: center;
  width: 100%;
  height: 2rem;
}

.buttonCancel {
  background: transparent !important;
  border: 2px solid #adb84e !important;
  color: #adb84e !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  padding: 0px 20px !important;
  font-size: 20px !important;
  font-family: Montserrat-ExtraBold, sans-serif !important;
  width: 200px;
}

.buttonAdd {
  background: #adb84e !important;
  border: 2px solid #adb84e !important;
  color: #fafafa !important;
  border-radius: 32px !important;
  text-transform: uppercase !important;
  padding: 0px 20px !important;
  font-size: 20px !important;
  font-family: Montserrat-ExtraBold, sans-serif !important;
  width: 200px;
}

.participantBox {
  margin-left: 1.5%;
  margin-top: 3%;
}

@media (max-width: 480px) {
  .box {
    width: 100%;
  }
  .participantBox {
    width: 300%;
  }
}

@media (max-width: 1024px) {
  .box {
    width: 80%;
  }
  .participantBox {
    width: 260%;
  }
  .fields {
    width: 33rem !important;
  }
}

@media (min-width: 1025px) {
  .participantBox {
    width: 200%;
  }

  .fields {
    width: 34rem !important;
  }
}

@media (max-width: 770px) {
  .fields {
    width: 30rem !important;
  }
}

@media (max-width: 850px) {
  .fields {
    width: 25rem !important;
  }
}

@media (max-width: 1450px) {
  .buttons {
    width: 30rem !important;
  }
  .fields {
    width: 29rem !important;
  }
}
