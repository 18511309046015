@font-face {
  font-family: 'Montserrat-Light';
  src: url('../../assets/typographies/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-style: normal;
}

.currentNavHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 2px;
  padding: 31px 0px 26px 30px;
  background-image: linear-gradient(to right, var(--color-white) 0%, rgba(255, 255, 255, 0));
}

.title {
  font-family: 'Montserrat-Light', sans-serif;
  font-size: 33px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--gris-fondo-menu-y-textos);
}

.currentNavHeaderContainer > * {
  margin: 0;
}

h2 {
  color: var(--color-gray);
}

.circleContainer {
  margin-top: 10px;
  height: 60px;
  display: flex;
  background-color: rgba(104, 179, 224, 0.2);
  justify-content: center;
  padding: 0 8px 0 8px;
  border-radius: 4px;
}

.circleContainer img {
  width: 40px;
}

.circleContainer h2 {
  font-size: 20px;
  font-weight: normal;
  margin-left: 10px;
  justify-content: center;
  text-align: center;
}

@media (max-width: 600px) {
  .currentNavHeaderContainer {
    height: 5rem;
  }
  .title {
    font-size: 28px !important;
  }
  h2 {
    display: flex;
    font-size: 15px !important;
    justify-content: center;
  }
  .circleContainer img {
    width: 30px;
  }
  .circleContainer {
    height: 50px;
    display: flex;
    background-color: rgba(104, 179, 224, 0.2);
    justify-content: center;
    padding: 0 8px 0 8px;
    border-radius: 4px;
  }
}

@media (max-width: 370px) {
  .title {
    font-size: 24px !important;
  }
}
