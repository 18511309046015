.modalTitle {
  margin-left: 16.5%;
}

.modalContainer {
  color: black;
  font-size: 18px;
  margin-left: 15%;
  margin-bottom: 3%;
  margin-top: 2%;
}

.modalRow {
  padding-bottom: 1.6%;
}

.modalColTitle {
  font-weight: bold;
}

.modalCol {
  background-color: #ededed;
}
