.btnAttachFile {
  display: flex;
  align-items: center;
}

.btnAttachFileActive {
  cursor: pointer;
}

.btnAttachFileDisabled {
  cursor: auto;
  opacity: 0.3;
}

.attachIconRotate {
  transform: rotate(-90deg);
}
