.background {
  background-size: cover !important;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-color: #adb84e6e !important;
}

.mainContainer {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  height: 100vh;
  width: 100%;
}

.policyPrivacyContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.policyPrivacyText {
  font-family: 'Montserrat-Regular', sans-serif !important;
  color: #474747 !important;
  font-size: 1.2rem !important;
  text-align: center !important;

  &:hover {
    color: #adb84e !important;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.logo {
  width: 140px !important;
}

.logoNoShowForm {
  width: 70%;
}

.questions {
  bottom: 0px; /* Ajusta según necesites */
  right: 0;
}
.termsContainer {
  position: fixed;
  bottom: 120px; /* Ajusta según necesites */
  right: 150px;
  width: 283px;
  height: 62px;
  border-radius: 32px 32px 0px 32px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px 0px #00000040;
  text-align: center;
  padding: 15px;
}

.inputContainer {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  font-family: 'Montserrat-Regular', sans-serif !important;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0;
}
.epico {
  width: 120px;
  margin: 10px 0 30px 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 386px;
  height: 386px;
  padding: 28px 20px 21px;
  border-radius: 100%;
  background-color: var(--color-white);
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.formContainerNoShowForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 386px;
  height: 386px;
  margin-top: 35%;
  padding: 20px;
  border-radius: 100%;
  background-color: var(--color-white);
  cursor: pointer;
}

.forgottenPasswordText {
  color: var(--verde-topbar-y-botones);
  cursor: pointer;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: 0;
  font-family: Montserrat-Regular, sans-serif !important;
  margin: 36px 99px 0 2px;
}

.button {
  margin: 28px 0 36px;
}

.questionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  background-color: #f9f9f9;
  border-radius: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: fixed; /* Fija la posición del contenedor */
  bottom: 0; /* Posiciona el contenedor en la parte inferior de la página */
  right: 0;
  margin: 70px;
  cursor: pointer;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
