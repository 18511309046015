html,
body,
#root {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #adb84ead;
  overflow: auto;
}

.mainBox {
  padding: 0;
}
