.modalView {
  flex-direction: column;
  min-height: 400px;
  width: 600px;
  display: flex;
  background-color: var(--color-white);
  padding: 3.5rem;
  border-radius: 32px;
}

.modalBox {
  margin-top: 20px;
}

.titleLabel {
  font-weight: bold;
  width: 25%;
  font-family: Montserrat-SemiBold, sans-serif !important;
}

.label {
  width: 50%;
}

.description {
  white-space: pre-line;
  width: 50%;
}

.chip {
  margin: 3px 5px 3px 0;
}
