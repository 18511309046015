.headerCell {
  background-color: #ffffff;
  font-size: 14px;
  border-bottom: 3px solid #a9ba37;
}

.cell {
  font-weight: bold;
  color: #515151;
}

.container {
  padding: 14px;
  border-radius: 32px;
  background: #fff;
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}