.loadingSpinner {
  position: absolute;
  right: 40%;
  overflow: hidden !important;
  bottom: 30%;
  color: red !important;
}

.LoadingSpinner {
  overflow: hidden;
}

.loadingSpinnerCalendar {
  position: absolute;
  right: 35%;
  bottom: 35%;
}
