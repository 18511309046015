.containerForm {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-end !important;
}
.box {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  margin-top: 2.5%;
  margin-left: 1.5%;
}

.fields {
}

.textModalAssignTraining {
  text-align: center !important;
  font-family: Montserrat-SemiBold, sans-serif;
  border-bottom: 2px solid #adb84e;
  padding: 20px;
  margin-left: 90px;
  margin-right: 90px;
}

.label {
  width: 100%;
  padding-left: 10px;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left;
  color: #adb84e !important;
  margin-bottom: 4px !important;
}

.dateFields {
  width: 10rem;
  margin-right: 220px !important;
}

.boxAddPatient > img {
  margin: 0 50px 0 0 !important;
  object-fit: contain !important;
}

.buttons {
  margin-top: 6.5%;
  align-items: flex-end;
  width: 40rem;
  height: 2rem;
}

.participantBox {
  margin-left: 1.5%;
  margin-top: 3%;
}

@media (max-width: 480px) {
  .box {
    width: 100%;
  }
  .participantBox {
    width: 300%;
  }
}

@media (max-width: 1024px) {
  .box {
    width: 80%;
  }
  .participantBox {
    width: 260%;
  }
}

@media (min-width: 1025px) {
  .participantBox {
    width: 200%;
  }
}

@media (max-width: 1450px) {
  .buttons {
    width: 30rem !important;
  }
}
