.newConversationContainer {
  flex-direction: column;
  min-height: 400px;
  width: 700px;
  display: flex;
  background-color: #f9f9f9;
  padding: 1.5rem;
  justify-content: space-between;
  border-radius: 32px;
}

.label {
  width: 25%;
  font-weight: bold;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.chip {
  margin: 3px 5px;
}
