.container {
  padding: 14px;
  border-radius: 32px;
  background: #fff;
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.buttonContainer {
  display: flex;
  justify-content: end;
  width: 100%;
}