.header {
  background-color: rgb(198 205 136);
  box-shadow: inset 0px 0px 80px 0px rgb(198 205 136);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.headerLogin {
  height: 150px;
  background-color: #95a531;
  box-shadow: inset 0px 0px 80px 0px #a9ba37;
}
.navigator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 100vh;
  background-color: rgb(198 205 136);
  padding-top: 40px;
}

.navigatorLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #a9ba37;
  margin-bottom: 20px;
}

.link {
  color: #fff;
  font-family: Montserrat-SemiBold, sans-serif !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: -0.004em !important;
  text-transform: uppercase !important;
  margin-right: 10px !important;
}

.linkLogin {
  color: #fff;
  font-family: Montserrat-SemiBold, sans-serif !important;
  font-size: 15px !important;
  line-height: 18px !important;
  letter-spacing: -0.004em !important;
}

.link:hover {
  color: #e8e7e7;
  transition: 0.4s;
}

.logo {
  object-fit: contain;
}

.text {
  color: #fff;
}
