.yearHeader {
  border-bottom: solid 3px dodgerblue;
}

.yearContainer {
  display: flex;
  justify-content: center;
  color: #a9ba37;
  font-weight: bold;
}

@media (max-width: 400px) {
  .yearContainer {
    font-size: 11px;
  }
}

@media (min-width: 401px) {
  .yearContainer {
    font-size: 15px;
  }
}

@media (min-width: 1280px) {
  .yearContainer {
    font-size: 1.5rem;
  }
}

.monthContainer {
  display: flex;
  justify-content: left;
  color: #a9ba37;
  font-weight: bold;
}

@media (max-width: 400px) {
  .monthContainer {
    width: 40px;
    font-size: 8px;
  }
}

@media (min-width: 401px) {
  .monthContainer {
    font-size: 13px;
  }
}

@media (min-width: 1280px) {
  .monthContainer {
    font-size: 1rem;
  }
}

.monthEvent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a9ba37;
  color: #ffffff;
  border-radius: 5px;
  margin: 0 0 2px 0;
  font-weight: bold;
  padding: 1%;
}

@media (max-width: 400px) {
  .monthEvent {
    font-size: 7px;
    padding: 3%;
  }
}

@media (max-width: 401px) {
  .monthEvent {
    font-size: 10px;
    padding: 3%;
  }
}

@media (max-width: 1280px) {
  .monthEvent {
    font-size: 10px;
  }
}

.tableRows {
  border: solid 1px #e3e3e3;
}
