.containerModal {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 4rem;
  width: 650px;
  background-color: var(--color-white);
  padding: 1.5rem;
  border-radius: 20px;
}

.label {
  padding-left: 10px;
  font-family: Montserrat, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  letter-spacing: -0.004em !important;
  text-align: left;
  color: #adb84e !important;
}
