.avatarWrapper {
  margin: auto 5px;
}

.conversationBlock {
  margin: auto 5px;
  width: 100%;
}

.conversationName {
  margin: 2px auto 1px auto;
  font-size: 20px;
}

.conversationDescription {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 14px;
}

.conversationBody {
  margin: 1px auto 2px auto;
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
}

.lastMessage {
  margin-top: 0;
}

.conversation {
  width: 99%;
  margin-top: 15px;
  background-color: transparent;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  border-radius: 32px;
  padding: 5px;
  border: 2px solid #adb84e;
}

.activeConversation {
  background-color: rgb(212, 228, 239);
}

.conversationBadge {
  margin: auto 5px;
}
