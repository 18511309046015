.wallContainer {
  display: flex;
  flex-direction: row;
  margin: 2px 0px 4px 2px;
}

.homeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 984px;
  height: 485px;
  border-radius: 27px;
  background: #f9f9f9;
  box-shadow: 0px 3px 3px 0px #00000040;
}

.notificationContainer {
  width: 60vh;
  background-color: #ffffff;
  padding: 20px 20px 5px 20px;
  margin: 8px 8px 8px 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.chatWidgetContainer {
  width: 60vh;
  height: fit-content;
  background-color: #ffffff;
  padding: 20px 20px 5px 20px;
  margin: 8px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
}

.chatIcon {
  width: 35px;
}

.arrowRight {
  width: 13px;
  margin: 0 5px 0 5px;
}

.noNotifications {
  text-align: center;
  font-size: 1.8rem;
}

.pagination {
  margin-right: auto;
  margin-left: auto;
}

.icon {
  width: 25px;
}

.containerFooter {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  color: var(--verde-topbar-y-botones);
}

.containerFooter h3 {
  font-size: 14px;
  font-weight: normal;
  color: var(--verde-topbar-y-botones);
  text-decoration: none;
  transition: all ease 0.4s;
}

.notificationBox {
  position: relative;
  padding: 10px;
  border: 1px solid #d1d1d1;
  width: 45vh;
  border-radius: 2px;
}

.notificationBox:before {
  content: '';
  display: block;
  position: absolute;
  top: 44.3%;
  right: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #d1d1d1;
}

.notificationBox:after {
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  top: 45%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-right-color: white;
}

.timelineDot {
  width: 25px;
  height: 25px;
  border-width: 4px;
}

@media (max-width: 1200px) {
  .wallContainer {
    flex-direction: column;
  }

  .notificationContainer {
    width: auto;
    margin: 8px;
  }

  .chatWidgetContainer {
    width: auto;
  }

  .notificationBox {
    width: 35vh;
  }
}

@media (max-width: 770px) {
  .notificationBox {
    width: 33vh;
  }
  .textBox {
    font-size: 40px;
  }
}

@media (max-width: 652px) {
  .notificationBox {
    width: 32vh;
  }

  .textBox {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .notificationBox {
    width: 30vh;
  }
  .textBox {
    justify-content: left;
  }
}

@media (max-width: 480px) {
  .notificationBox {
    width: 20vh;
  }
}

@media (max-width: 410px) {
  .notificationBox {
    width: 15vh;
  }
  .containerFooter {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
}
