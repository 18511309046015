.calendarContainer {
  column-gap: 2rem;
  width: 100%;
  padding: 0px 45px 0px 45px;
}

.headerContainer {
  text-align: left;
  width: 90%;
  margin: 10px;
}

@media (max-width: 349px) {
  .calendarContainerLeft {
    width: 280px;
  }
}

@media (min-width: 350px) {
  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
  }
}
.buttonGenerate {
  margin-left: 10px;
}

.datePicker {
  background-color: #8e9631 !important;
}

.MuiPickersToolbar .toolbar {
  background-color: #8e9631 !important;
}

.calendarContainerLeft {
  display: block !important;
  text-align: center !important;
  padding: 0;
  border-radius: 3px;
  background-color: #f9f9f9 !important;
  margin-top: 15px;
}

.newEventButton {
  background-color: #adb84e !important;
  color: #ffffff !important;
  font-family: 'Montserrat-Regular', sans-serif !important;
  height: 74px !important;
  border-radius: 32px !important;
  border: 2px solid #adb84e !important;
  display: flex;
  width: 200px !important;
  flex-direction: column;
}

.newEventButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newEventText {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.004em;
  text-align: left;
  color: #f9f9f9;
  margin-bottom: 4px;
}

.newEventTextExercises {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.004em;
  color: #f9f9f9;
  margin-bottom: 4px;
}

.calendarContainerLeft > button {
  position: relative !important;
  margin: 4px;
  height: 100px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.calendar {
  border-radius: 6px 6px 3px 3px;
  border: 1px dotted #adb84e;
  overflow: hidden;
  width: auto;
  background-color: #f9f9f9 !important;
}

.calendar header {
  box-shadow: none;
}

.calendar div[role='tablist'] {
  background-color: #f9f9f9;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar div[role='tablist'] button[aria-selected='true'] {
  background-color: #f9f9f9;
  color: #282828;
  box-shadow: 0 0 #000;
  border-bottom: 0 #000 solid;
}

.calendar .MuiTabs-indicator {
  display: none !important;
}

.parsetabs {
  background-color: #ebedeb !important;
  color: #515151 !important;
}

.parsetabs span {
  font-weight: bold;
  text-align: left;
}

.parsetabsActive {
  background-color: #f9f9f9 !important;
  color: #515151 !important;
}

.parsetabsActive span {
  font-weight: bold;
  text-align: left;
}
.caltabs {
  width: 100%;
}
.caltabs > div > span {
  top: 0;
  height: 4.5px;
  background-color: #474747;
}

.calendar > div > div {
  margin: 10px;
}

@media (max-width: 900px) {
  .calendarContainer {
    display: flex;
    flex-direction: column;
  }
  .calendarContainerLeft {
    row-gap: 1rem;
    height: fit-content;
    max-width: 320px;
    margin: 0 auto 20px auto;
  }
  .caltabs {
    visibility: hidden;
  }
}

.font1 {
  width: 40px !important;
}
